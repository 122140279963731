import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components'

let cmain = '#C0573B'
let black = 'rgb(38,38,38)'

let id_temp = 'feby-tunggul'
let id = 'kiki-yogi'
let inisial_co = 'Yogi'
let inisial_ce = 'Kiki'
let lengkap_co = 'Yogi Bagus Setyawan'
let lengkap_ce = 'drg. Rizqiyatus Sholechah'
let bapak_co = 'Bapak Suparman (Alm)'
let ibu_co = 'Ibu Darmilah'
let bapak_ce = "Bapak H. Mochtar Abdullah "
let ibu_ce = "Ibu Hj. Sulistyowati, S.Pd"
let ig_co = "yogibagus_s"
let ig_ce = "gigikukiki"

let foto_ce = pw(id, "ce.png")
let foto_co = pw(id, "co.png")
let waktunikah = "02/14/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/Lu9LwcZ8ibrNHHd86"
let gcalendar = `https://calendar.google.com/event?action=TEMPLATE&tmeid=MzFuZHBkajd2dmxtN3A0c2c5aWliMTUzY2cgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com`
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3963.4905378335957!2d110.65832756875612!3d-6.585781485813909!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwMzUnMDUuOCJTIDExMMKwMzknNDIuOSJF!5e0!3m2!1sen!2sid!4v1609663785624!5m2!1sen!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  width:100%;
  font-family:'Pacifico', cursive;
  -webkit-text-fill-color:#E62E7A ; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1.25px;
  -webkit-text-stroke-color: white;
`;


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: "${this.pesan.current.value?this.pesan.current.value:''}"`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let pinktua = "#E62E7A"
        let pinkmuda = "#E2958F"
        let dusty = "#E3C4C3"
        let maroon = "#571D1C"
        let slider = []
        let slide = [
            "RJ-Ameng-00132.jpg",
            "RJ-Ameng-00156.jpg",
            "RJ-Ameng-00306.jpg",
            "RJ-Ameng-00549.jpg",
            "RJ-Ameng-00631.jpg",
            "RJ-Ameng-00796.jpg",
            "RJ-Ameng-00845.jpg",
            "RJ-Ameng-00866.jpg",
            "RJ-Ameng-00929 - Copy.jpg",
            "RJ-Ameng-00987.jpg",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id_temp, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet"></link>
                    
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-sm-3`} style={{ marginTop: '0' }}>
                                        Dear : {query ? query : ''} <br />
                                        You're Invited!
                                        </h2>
                                }</Item>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>

                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center 
                                    ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: pinktua, backgroundColor: 'white' }}>
                                    <p className="mb-0">
                                        <img src="https://www.flaticon.com/svg/static/icons/svg/833/833472.svg"
                                            style={{ height: '25px', width: '25px' }}
                                            className="mr-3"
                                        />
                                     Open Invitation
                                    </p>

                                </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid id='g3-header' className='position-relative' style={{
                                    backgroundImage: `url('${pw(id, "foto1.jpeg")}')`
                                }}>
                                    <div className="position-absolute w-100"
                                        style={{ bottom: '10%', left: 0 }}>

                                        <h1 className="w-100 text-center"
                                            style={{ fontFamily: 'Pacifico, cursive', color: pinktua }}>
                                            {inisial_ce} & {inisial_co}
                                        </h1 ><br />
                                        <p className="w-100 text-center" style={{ color: pinktua }}>
                                            Februari 14, 2021
                                            </p>
                                    </div>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} kiki />
                                <Container fluid
                                    style={{ backgroundColor: pinkmuda }}
                                    className="py-3 position-relative">
                                    {/* <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className="img-fluid w-100" />
                                    </Col> */}
                                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className="mirror img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className="flip180-m img-fluid w-100" />
                                    </Col>
                                    {/* <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className=" flip180  img-fluid w-100" />
                                    </Col> */}
                                    <Item>
                                        <Col xs={10} md={4}>
                                            <img src={pw(id, "bismillah-black.svg")} className="img-fluid w-100" />
                                        </Col>
                                    </Item>
                                    <p className="w-100 text-center pt-3"
                                        style={{ fontSize: '16px', color: pinktua }}>
                                        “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.”
                                    <br /><br />
                                        <span style={{ color: 'black' }}>(Ar-Rum: 21)</span>

                                    </p>
                                </Container>


                                <Container fluid id='sectiongold55'
                                    className="py-5 dinny position-relative" style={{ backgroundColor: dusty }}>
                                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className="img-fluid w-100" />
                                    </Col>
                                    {/* <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className="mirror img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className="flip180-m img-fluid w-100" />
                                    </Col> */}
                                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className=" flip180  img-fluid w-100" />
                                    </Col>
                                    <h1 className="w-100 text-center" style={{
                                        color: pinktua,
                                    }}>
                                        KEDUA MEMPELAI
                                    </h1>
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundColor: 'transparent' }}>

                                                <Item>
                                                    <Col xs={8}>
                                                        <img src={foto_ce} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '24px',
                                                            fontFamily: 'Pacifico, cursive', color: pinktua
                                                        }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '20px', color: 'black' }}>
                                                        Putri Keempat<br />
                                                        {bapak_ce}  <br />

                                                        dan {ibu_ce} (Jepara)
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw(id, "logoig.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundColor: 'transparent' }}>

                                                <Item>
                                                    <Col xs={8}>
                                                        <img src={foto_co} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '24px',
                                                        fontFamily: 'Pacifico, cursive', color: pinktua
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '20px', color: 'black' }}>
                                                        Putra Pertama<br />
                                                        {bapak_co}
                                                        <br />

                                                        dan {ibu_co} (Yogyakarta)
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={pw(id, "logoig.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid style={{ backgroundColor: dusty }} className="py-3">
                                    <p className="w-100 text-center"
                                        style={{ color: 'black' }}>
                                        السَّلاَمُ عَلَيْكُمْ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ
                                        </p>
                                    <Item>
                                        <img src="https://www.flaticon.com/svg/static/icons/svg/3656/3656836.svg"
                                            style={{ width: '50px', height: '50px' }}
                                            className="m-3" />
                                    </Item>
                                    <Item>
                                        <h1 style={{ borderBottom: `2px solid ${pinktua}`, color: pinktua, fontSize: '1.5rem' }}
                                            className="py-3">
                                            Acara Pernikahan
                                        </h1>
                                    </Item>
                                    <Item>
                                        <div className="p-3" style={{ backgroundColor: 'white' }}>
                                            <p style={{ color: pinktua }} className="mb-0">
                                                AKAD NIKAH
                                                    </p>
                                        </div>
                                    </Item>
                                    <p className="w-100 text-center" style={{ color: 'white' }}>
                                        Ahad Legi<br />
14 Februari 2021<br />
13.00 WIB - SELESAI<br />
                                        <span style={{ color: 'black',fontSize:'16px'}}>(Masjid Baitus - Salam, Kel. Ujung Batu, Jepara)</span>

                                    </p>
                                    <Item>
                                        <div className="p-3" style={{ backgroundColor: 'white' }}>
                                            <p style={{ color: pinktua }} className="mb-0">
                                                RESEPSI
                                                    </p>
                                        </div>
                                    </Item>
                                    <p className="w-100 text-center" style={{ color: 'white' }}>
                                        Minggu<br />
14 Februari 2021<br />

14.00 - 17.00 WIB<br />
                                        <span style={{ color: 'black',fontSize:'16px' }}>(Kediaman Mempelai Wanita)</span>

                                    </p>

                                </Container>

                                <Container fluid style={{ backgroundColor: dusty }} className="py-3">
                                    <Item>
                                        <img src="https://www.flaticon.com/svg/static/icons/svg/2928/2928892.svg"
                                            style={{ width: '50px', height: '50px' }}
                                            className="m-3" />
                                    </Item>
                                    <Item>
                                        <h1 style={{ borderBottom: `2px solid ${pinktua}`, color: pinktua, fontSize: '1.5rem' }}
                                            className="py-3">
                                            Lokasi
                                        </h1>
                                    </Item>
                                    <p className="
                                    w-100 text-center" style={{ color: 'white' }}>
                                        Jl. KH. Shidiq, RT 08 / RW 03, Ujung Batu, Jepara, Jawa Tengah, 59416

                                    </p>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${pinkmuda}`,
                                                backgroundColor: pinkmuda,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'black' }}>
                                                    <b>GOOGLE MAPS</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Container fluid className=" px-md-5 col-md-10 px-3"
                                    style={{
                                        color: pinktua, fontFamily: 'Josefin Sans, sans-serif',
                                        backgroundColor: pinkmuda
                                    }}
                                >

                                    <Row className="position-relative px-2 py-3">
                                        <h1 className="peri w-100 text-center"
                                            style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                                            Our Love Story
                                            </h1>
                                    </Row>
                                    <Item>
                                        <Col xs={6}>
                                            <h1 className="peri w-100 text-center"
                                                style={{ fontWeight: 700, fontSize: '1.25rem' }}>
                                                First Date
                                            </h1>
                                            <p className="w-100 text-center" style={{ fontSize: '14px' }}>
                                                Juny, 02, 2015
                                            </p>
                                        </Col>
                                        <Col xs={1} className="p-0">
                                            <img src={pw(id, "div.png")} className="w-100 img-fluid mh-50" />
                                        </Col>
                                        <Col xs={5}>
                                            <img src={pw(id, "gb1.png")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <Col xs={5}>
                                            <img src={pw(id, "gb3.png")} className="w-100 img-fluid" />
                                        </Col>

                                        <Col xs={1} className="p-0">
                                            <img src={pw(id, "div.png")} className="w-100 img-fluid mh-50" />
                                        </Col>
                                        <Col xs={6}>
                                            <h1 className="peri w-100 text-center"
                                                style={{ fontWeight: 700, fontSize: '1.25rem' }}>
                                                Engagement
                                            </h1>
                                            <p className="w-100 text-center" style={{ fontSize: '14px' }}>
                                                October, 11, 2020
                                            </p>
                                        </Col>

                                    </Item>
                                    <Item>
                                        <Col xs={6}>
                                            <h1 className="peri w-100 text-center"
                                                style={{ fontWeight: 700, fontSize: '1.25rem' }}>
                                                Wedding Day
                                            </h1>
                                            <p className="w-100 text-center" style={{ fontSize: '14px' }}>
                                                February, 14, 2021
                                            </p>
                                        </Col>
                                        <Col xs={1} className="p-0">
                                            <img src={pw(id, "div.png")} className="w-100 img-fluid mh-50" />
                                        </Col>
                                        <Col xs={5}>
                                            <img src='https://www.flaticon.com/svg/static/icons/svg/703/703213.svg' className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                <Container fluid style={{ backgroundColor: pinkmuda }} className="p-3">
                                    <Item>
                                        <h1 style={{ borderBottom: `2px solid ${pinktua}`, color: pinktua, fontSize: '1.5rem' }}
                                            className="py-3">
                                            Moment Bahagia
                                        </h1>
                                    </Item>
                                    <Item>
                                        {
                                            [1, 2, 3, 4,  6,5, 7, 8, 9, 10,11]
                                                .map(v => {
                                                    return (
                                                        <Col xs={12} className="p-2">
                                                            <img src={pw(id,v+".jpg")} className="w-100 img-fluid"/>
                                                        </Col>
                                                    )
                                                })
                                        }
                                    </Item>
                                    <h1 className="peri w-100 text-center"
                                        style={{ fontWeight: 700, fontSize: '1.25rem',color:pinktua }}>
                                        Engagement
                                            </h1>
                                            <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src={pw(id,"Engagement Kiki - Yogi.mp4")}></iframe>
                                    </div>
                                    <h1 className="peri w-100 text-center"
                                        style={{ fontWeight: 700, fontSize: '1.25rem',color:pinktua }}>
                                        Pre-Wedding
                                            </h1>
                                            <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src={pw(id,"Pre Wedding Kiki - Yogi.mp4")}></iframe>
                                    </div>

                                </Container>



                                <Container className="py-3" style={{backgroundColor:'#E3C4C3'}}>
                                <Container fluid id='sectiongold55'
                                    className="py-5 dinny position-relative" style={{ backgroundColor: dusty }}>
                                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className="img-fluid w-100" />
                                    </Col>
                                    {/* <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className="mirror img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id,"flower.png"), 95)} className="flip180-m img-fluid w-100" />
                                    </Col> */}
                                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                        <img src={gambar(pw(id, "flower.png"), 95)} className=" flip180  img-fluid w-100" />
                                    </Col>
                                    <h1 className="w-100 text-center" style={{
                                        color: pinktua,
                                    }}>
                                       Kami yang Berbahagia
                                    </h1>
                                    <p className="text-center w-100" style={{color:pinktua}}>
                                        Kel. {bapak_ce}<br/>
                                        {ibu_ce} (SDN Bulu) <br/><br/>
                                        Kel. {bapak_co}<br/>
                                        {ibu_co}
                                    </p>
                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '24px',
                                                            fontFamily: 'Pacifico, cursive', color: pinktua
                                                        }}>
                                                        {inisial_ce} & {inisial_co}
                                                    </h1>
                                    </Container>
                                    <Item>
                                        <Title>
                                            Protokol Kesehatan
                                        </Title>
                                        <p className="w-100 text-center p-2 mb-0" 
                                        style={{color:pinktua,backgroundColor:'white',
                                        borderRadius:'10px',
                                        fontSize:'16px'
                                        }}>
                                        Dimohon bagi para tamu undangan untuk tetap memenuhi protokol kesehatan covid 19 untuk keselamatan bersama
                                        </p>
                                        <Col xs={12} md={6}>
                                            <img src={pw(id,"covid.JPG")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: 'Tinos,serif',
                                                        color: pinktua
                                                    }}>
                                                        Send Your Wishes
                                                     </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.alamat} type='text' className="col-12 w-100 text-center bigger" placeholder="Alamat" name='alamat' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        
                                                        <Item>
                                                                        <div id="formradio">
                                                                            <div class="custom_radio row justify-content-center">
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: true })
                                                                                }
                                                                                }>
                                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                                    <label style={{ color: pinktua }} for="featured-1">Hadir</label>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: false })
                                                                                }
                                                                                } className="pl-5">
                                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                                    <label style={{ color: pinktua }} for="featured-2"
                                                                                    >Tidak Hadir</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: pinktua, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

